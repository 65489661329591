<template>
  <v-alert
    v-if="!profile.allow_events"
    type="error"
  >
    You may not view events.
  </v-alert>
  <v-alert
    v-else-if="!$online"
    type="error"
  >
    An internet connection is required to view events.
  </v-alert>
  <v-card v-else>
    <v-card-title>
      Events
    </v-card-title>
    <EventList
      :can-create="true"
      :allow-filtering="true"
      url="/quest/event/"
    />
  </v-card>
</template>

<script>
import EventList from '@/components/EventList.vue'
import { mapState } from 'vuex'

export default {
  components: {
    EventList,
  },
  data: function() {
    return {
      alerts: [],
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
  },
}
</script>
